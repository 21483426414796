import { useState, useEffect, useCallback } from 'react';
import {
    Row, Col,
    Form, FormGroup, Label,
    /* Button, */ Input, Collapse,
    ListGroup, ListGroupItem, Spinner,
} from 'reactstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import ReportFiltersPreview from './filtersPreview';
import Header from '../../../components/header';
import SiteSidebar from '../../../components/siteSidebar';
// import ConfirmationModal from '../../../components/confirmationModal';
import { getVendor, getVendorReports, getVendorReportsYearsMonths } from '../../../actions';
import { getFormatTs, months as displayMonths } from '../../../utils';

function Archive() {

    const { portfolioId, siteId, vendorId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { toTz } = useSelector(state => state.tz);
    

    const queryParams = new URLSearchParams(location.search);
    const selectedMonth = queryParams.get('month');
    const selectedYear = queryParams.get('year');

    const [ name, setName ] = useState( '' );
    const [ reports, setReports ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( true );
    const [ years, setYears ] = useState( [] );
    const [ months, setMonths ] = useState( [] );
    // const [ modalOpen, setModalOpen ] = useState( false );
    // const toggle = () => setModalOpen( !modalOpen );

    const setQueryParams = (newParams) => {
        // Parse current query params
        const currentParams = new URLSearchParams(location.search);
    
        // Merge new params, overriding duplicates
        Object.keys(newParams).forEach(key => {
          if( newParams[key] == null ) {
            currentParams.delete(key);
          } else {
            currentParams.set(key, newParams[key]);
          }
        });
    
        // Update the URL
        navigate({
          pathname: location.pathname,
          search: currentParams.toString()
        });
    };

    const handleGetReports = useCallback(() => {
        const params = {
            isArchived: true,
            month: selectedMonth,
            year: selectedYear,
            toTz: toTz,
        };
        getVendorReports( vendorId, params, ( err, res ) => {
            if( !err ) {
                setReports( res.data );
            }
        });
    }, [ vendorId, selectedMonth, selectedYear, toTz ]);

    const handleGetVendor = useCallback(() => {
        getVendor( vendorId, ( err, res ) => {
            console.log( err, res );
            setIsLoading( false );
            if( !err ) {
                setName( res.data.name );
            }
        });
    }, [ vendorId ]);

    useEffect( () => {
        handleGetVendor();
        handleGetReports();
    }, [ handleGetVendor, handleGetReports ] );

    useEffect(() => {
        const params = {
            isArchived: true,
            year: selectedYear,
            toTz: toTz
        };
        getVendorReportsYearsMonths( vendorId, params, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                setYears( res.data.years );
                setMonths( res.data.months );
            }
        });

    }, [ vendorId, selectedYear, toTz ])

    return (
        <div className="App">
            <Header />

            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
    
                <SiteSidebar />
                <main className="w-100 p-3">
                    <div className="row border-bottom mb-2">
                        <Col sm={6}>
                            <h3>Vendor: {name}</h3>
                        </Col>
                        <Col sm={6} className="text-end">
                            <Link to={'/portfolios/' + portfolioId + '/sites/' + vendorId + '/vendors/' + vendorId}>
                                Return
                            </Link>
                        </Col>
                    </div>

                    <Form>
                        <FormGroup>
                            <Label>Archived Reports</Label>
                            {isLoading && <Spinner />}

                            <Row className="row-cols-lg-auto g-3 align-items-center">
                                
                                <Col>
                                    <FormGroup>
                                        <Label>Year:</Label>
                                        <Input type="select"
                                            value={selectedYear ? selectedYear : ''}
                                            onChange={(e) => setQueryParams({
                                                year: e.target.value === '' ? null : e.target.value
                                            })}
                                        >
                                            <option value={''}>All</option>
                                            {years.length > 0 && years.map(year => (
                                                <option>{year}</option>
                                            ))}
                                            <option>something</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label>Month:</Label>
                                        <Input type="select"
                                            value={selectedMonth ? selectedMonth : ''}
                                            onChange={(e) => setQueryParams({
                                                month: e.target.value === '' ? null : e.target.value
                                            })}
                                        >
                                            <option value={''}>All</option>
                                            {months.length > 0 && months.map(month => (
                                                <option value={month}>{displayMonths[month - 1]}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                
                            </Row>

                            <Collapse isOpen={!isLoading}>
                                <ListGroup>
                                    {reports.map((report, index) => {
                                        return (
                                            <ListGroupItem key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/' + report.id}>
                                                    {report.name}
                                                </Link>
                                                <div>
                                                    {getFormatTs(report.createdTs)}
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>
                            </Collapse>

                        </FormGroup>
                    </Form>

                </main>
            </div>
            
        </div>
    );
}

export default Archive;
