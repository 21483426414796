import { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { getCameras, getPrompts } from '../../actions';

function CameraAndPromptFilter( props ) {
    const portfolioId = props.portfolioId;
    const siteId = props.siteId;
    // const cameras = props.cameras;
    // const prompts = props.prompts;
    const cameraId = props.cameraId;
    const promptId = props.promptId;
    const triggered = props.triggered;

    const [ cameras, setCameras ] = useState( [] );
    const [ prompts, setPrompts ] = useState( [] );

    useEffect( () => {

        getCameras( portfolioId, siteId, {}, ( err, res ) => {
            if( !err ) {
                setCameras( res.data );
            }
        });
    
        getPrompts( portfolioId, siteId, {}, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                setPrompts( res.data );
            }
        });
    
    }, [portfolioId, siteId] );

    return (
        <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col>
                <FormGroup>
                    <Label></Label>
                    <Input size="sm" type="select" onChange={ e => props.setQueryParams({ cameraId: e.target.value === '-1' ? null : e.target.value }) }>
                        <option value={'-1'}>All cameras</option>
                        {cameras.map( camera =>
                            <option selected={camera.id == cameraId} value={camera.id}>{camera.name}</option>
                        )}
                    </Input>
                </FormGroup>
            </Col>

            <Col>
                <FormGroup>
                    <Label></Label>
                    <Input size="sm" type="select" onChange={ e => props.setQueryParams({ promptId: e.target.value === '-1' ? null : e.target.value }) }>
                        <option value={'-1'}>All prompts</option>
                        {prompts.map( prompt =>
                            <option selected={prompt.id == promptId} value={prompt.id}>{prompt.name}</option>
                        )}
                    </Input>
                </FormGroup>
            </Col>

            <Col>
                <FormGroup switch>
                    <Input type="switch" checked={ triggered == '1' ? true : false} onChange={ e => props.setQueryParams({ triggered: e.target.checked === true ? '1' : '0' }) } />
                    <Label check> Detections</Label>
                </FormGroup>
            </Col>
        </Row>
    );

}

export default CameraAndPromptFilter;