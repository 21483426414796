import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function ConfirmationModal({
    children,
    onToggle,
    onConfirm,
    isOpen,
    header,
    type = 'submit',
    bodyStyle = { textAlign: 'center' },
    ...props
}) {
    // const isOpen = props.isOpen;
    // const header = props.header;
    // const bodyStyle = props.bodyStyle || { textAlign: "center" };
    // const type = props.type || "submit";

    return (
        <Modal isOpen={isOpen} toggle={onToggle} {...props}>
            <ModalHeader toggle={onToggle}>
                {'Confirm: ' + header}
            </ModalHeader>
            <ModalBody style={bodyStyle}>
                {children}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onToggle}>
                    Cancel
                </Button>
                <Button outline color={type === "delete" ? "danger": "primary"} onClick={onConfirm}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default ConfirmationModal;