import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import Header from './../../components/header';
import { Card, CardTitle, CardBody, CardImg, CardSubtitle, CardText, CardFooter, Row, Table, Col, Form, Button,
/* Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge */ } from 'reactstrap';
import { Link, /* Navigate, */ useNavigate, useParams, useLocation } from 'react-router-dom';
import { getSite, /* patchSite,  getCameras, getPrompts, */ getRuns } from '../../actions';
// import OpenIncidents from './../../components/incidents/open';
// import IncidentsListGroup from './../../components/incidents/listGroup';
// import RunsStream from '../../components/runs/stream';
// import Breadcrumb from '../../components/breadcrumb';
// import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import DateTimeFilter from '../../components/runs/dateTimeFilter';
import CameraAndPromptFilter from '../../components/runs/cameraAndPromptFilter';
import { timeAgo } from '../../utils';
import { utcToLocal, timeToMilitaryHour } from '../../utils';

function Runs() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();
  const { toTz } = useSelector(state => state.tz);
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  //const timeblock = queryParams.get('timeblock');
  const promptId = queryParams.get('promptId');
  const cameraId = queryParams.get('cameraId');
  const resultsPerPage = queryParams.get('resultsPerPage');
  const method = queryParams.get('method');
  const sortColumn = queryParams.get('sortColumn');
  const triggered = queryParams.get('triggered');
  const fromTs = queryParams.get('fromTs');
  const toTs = queryParams.get('toTs');
  const startHour = queryParams.get('startHour');
  const endHour = queryParams.get('endHour');
  const byDay = queryParams.get('byDay');
  const interval = queryParams.get('interval');
  
  const [ siteName, setSiteName ] = useState( '' );
  const [ runs, setRuns ] = useState( [] );
  const [ page, setPage] = useState(1);
  const [ loading, setLoading] = useState(false);
  const [ hasMore, setHasMore] = useState(true);

  const localFromTs = fromTs ? utcToLocal( fromTs ) : '';
  const localToTs = toTs ? utcToLocal( toTs ) : '';
  const selectedDays = byDay ? byDay.split(',').map(i => parseInt(i)): [];
  const militaryStartHour = startHour ? timeToMilitaryHour( startHour ): -1;
  const militaryEndHour = endHour ? timeToMilitaryHour( endHour ): -1;
  const selectedInterval = interval || '';

  const observer = useRef();

  const setQueryParams = (newParams) => {
    // Parse current query params
    const currentParams = new URLSearchParams(location.search);

    // Merge new params, overriding duplicates
    Object.keys(newParams).forEach(key => {
      if( newParams[key] == null ) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, newParams[key]);
      }
    });

    // Update the URL
    navigate({
      pathname: location.pathname,
      search: currentParams.toString()
    });
  };

  useEffect( () => {
    handleGet();
  }, [ page ] );

  useEffect( () => {
    setRuns( [] );
    setHasMore( true );
    handleGet( true );
  }, [ portfolioId, siteId, promptId, cameraId, resultsPerPage, method, sortColumn, fromTs, toTs, interval, startHour, endHour, byDay, triggered ] );

  const handleGet = ( clear ) => {
    console.log( portfolioId, siteId, promptId, cameraId );
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setSiteName( res.data.name );
        }
    });

    setLoading(true);

    getRuns( portfolioId, siteId, { cameraId, promptId, resultsPerPage, method, sortColumn, fromTs, toTs, interval, startHour, endHour, byDay, toTz, page, triggered }, ( err, res ) => {
      console.log( err, res );
      setLoading(false);

      if( !err ) {
        if( clear )
          setRuns( res.data );
        else
          setRuns((prevRuns) => [...prevRuns, ...res.data]);
        if( res.data.length === 0 ) setHasMore( false );
      }
    });
    

  };



  const lastItemRef = useCallback(
    (node) => {
      console.log( 'lastItemRef' );
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );



  useEffect(() => {
    if (runs.length > 0) {
      // Trigger ref assignment when items are first added
      const lastItem = document.querySelector(`[data-index="${runs.length - 1}"]`);
      if (lastItem) {
        lastItemRef(lastItem);
      }
    }
  }, [runs, lastItemRef]);




  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">

            <h3>Runs for {siteName}</h3>

            <Form>
            {/* <Row className="row-cols-lg-auto g-3 align-items-center">
              <Col>
                <FormGroup>
                  <Label></Label>
                  <Input size="sm" type="select" onChange={ e => setQueryParams({ cameraId: e.target.value === '-1' ? null : e.target.value }) }>
                    <option value={'-1'}>All cameras</option>
                    {cameras.map( camera =>
                      <option selected={camera.id == cameraId} value={camera.id}>{camera.name}</option>
                    )}
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label></Label>
                <Input size="sm" type="select" onChange={ e => setQueryParams({ promptId: e.target.value === '-1' ? null : e.target.value }) }>
                  <option value={'-1'}>All prompts</option>
                  {prompts.map( prompt =>
                    <option selected={prompt.id == promptId} value={prompt.id}>{prompt.name}</option>
                  )}
                </Input>
                </FormGroup>
              </Col>

              <Col>
                  <FormGroup switch>
                    <Input type="switch" checked={ triggered == '1' ? true : false} onChange={ e => e.target.checked === true ? setQueryParams({ triggered: '1' }) : setQueryParams({ triggered: '0' }) } />
                    <Label check> Detections</Label>
                  </FormGroup>
              </Col>

              </Row> */}

              <CameraAndPromptFilter
                setQueryParams={setQueryParams}
                portfolioId={portfolioId}
                siteId={siteId}
                cameraId={cameraId}
                promptId={promptId}
                triggered={triggered}
              />

              <DateTimeFilter
                setQueryParams={(newParams) => {
                  setQueryParams(newParams);
                  setPage(1);
                }}
                localFromTs={localFromTs}
                localToTs={localToTs}
                militaryStartHour={militaryStartHour}
                militaryEndHour={militaryEndHour}
                selectedDays={selectedDays}
                selectedInterval={selectedInterval}
              />

            </Form>
            {/*<Form>
              <FormGroup>
                <Label for="exampleSelect">
                  Results per page
                </Label>
                <Input
                  type="select"
                  onChange={ e => setQueryParams({ resultsPerPage: e.target.value }) }
                >
                  <option>
                    10
                  </option>
                  <option>
                    100
                  </option>
                  <option>
                    500
                  </option>
                </Input>
              </FormGroup>
            </Form>*/}


            <Row>
              {runs.map( (run, index) => 
                <Col key={index} ref={index === runs.length - 1 ? lastItemRef : null} data-index={index} xs={12} sm={6} md={4} className="mb-4" style={{height: '480px'}}>
                  <Card className="h-100">
                    <a href={run.imageUrl} target="_blank">
                      <CardImg 
                        src={run.imageUrl}
                        top
                        height="180px"
                      />
                    </a>
                    <CardBody>
                      <CardTitle tag="h5">
                        <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + run.id}>{run.id}: {run.prompt}</Link>
                      </CardTitle>
                      <CardSubtitle>
                        <small className="text-muted">
                          <div>{timeAgo( run.minutesElapsed )}</div>
                          <div>{(new Date( run.createdTs )).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})}</div>
                          <div>{run.createdTs}</div>
                        </small>
                      </CardSubtitle>
                      <CardText className="mt-2 lh-sm" style={{height: '4em'}}>
                        <Table>
                          <tr>
                            <td>Prompt:</td>
                            <td>{run.promptName}</td>
                          </tr>
                          <tr>
                            <td>Response:</td>
                            <td>{run.response}</td>
                          </tr>
                          <tr>
                            <td>Method:</td>
                            <td>{run.method}</td>
                          </tr>
                          <tr>
                            <td>Triggered:</td>
                            <td>{run.triggered}</td>
                          </tr>
                        </Table>

                      </CardText>
                    </CardBody>
                    <CardFooter>
                      Filter:
                      {' '}
                      <Button outline size="sm" onClick={ () => setQueryParams({ promptId: run.promptId }) }>Prompt</Button>
                      {' '}
                      <Button outline size="sm" onClick={ () => setQueryParams({ method: run.method }) }>Method</Button>
                      {' '}
                      <Button outline size="sm" onClick={ () => setQueryParams({ cameraId: run.cameraId }) }>Camera</Button>
                    </CardFooter>
                  </Card>
                </Col>
              )}
            </Row>

            {loading && <p>Loading...</p>}


          </main>

          </div>

      
    </div>
  );
}

export default Runs;
