import React, { useEffect, useCallback, useState } from 'react';
import { getVendorReports } from '../../../actions';
import RunsPreview from '../../runs/preview';

function ReportsSummary({ portfolioId, siteId, vendorId }) {
    const [ reports, setReports ] = useState([]);
    const [ totalReportsLength, setTotalReportsLength ] = useState( -1 );
    
    const reportsLength = reports.length;
    const reportsLengthLimit = 2;

    const handleGet = useCallback(() => {
        getVendorReports( vendorId, { isArchived: false }, ( err, res ) => {
            if( !err ) {
                setTotalReportsLength( res.data.length );
                setReports( res.data.length > reportsLengthLimit ? res.data.slice(0, reportsLengthLimit): res.data );
            }
        });
    }, [vendorId]);
    
    useEffect(() => {
        handleGet()
    }, [handleGet]);

    return (
        <div>
            {reports.map((report) => {
                const filters = report.filters;
                return (
                    <React.Fragment>
                        <h4>{report.name}</h4>
                        <RunsPreview
                            portfolioId={portfolioId}
                            siteId={siteId}
                            filters={filters}
                        />
                    </React.Fragment>
                )
            })}

            <div className="row mt-2 text-center">
                <p>Showing {reportsLength} of {totalReportsLength} reports</p>
            </div>
        </div>
    )
}

export default ReportsSummary;