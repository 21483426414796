
export function formatStatus( s ) {
    if( s === 'open' ) return 'Open';
    if( s === 'mislabeled' ) return 'Mislabeled';
    return s;
}

export function timeAgo(mins) {
    let message = "";
    const minutesInDay = 1440; // 60 * 24
    const minutesInHour = 60;

    const days = Math.floor(mins / minutesInDay);
    const hours = Math.floor((mins % minutesInDay) / minutesInHour);
    const minutes = Math.floor( mins % minutesInHour );

    if (days > 0) {
        message += `${days} day${days > 1 ? 's' : ''}`;
    }
    else {
        if (hours > 0) {
            if (message.length > 0) message += " and ";
            message += `${hours} hour${hours > 1 ? 's' : ''}`;
        }
        if (minutes > 0) {
            if (message.length > 0) message += " and ";
            message += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }    
    }
    if (message.length === 0) {
        message = "Less than a minute";
    }
    return message + " ago";
}


export * from './dateTime';
export * from './vendors';
// export * from './pdf';