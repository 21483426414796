import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { /* Form, FormGroup, */ Table, Button, Spinner, Col } from 'reactstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DownloadModal from './download';
import Header from '../../../components/header';
import SiteSidebar from '../../../components/siteSidebar';
import ConfirmationModal from '../../../components/confirmationModal';
import RunsPreview from '../../../components/runs/preview';
import {
    getCamera,
    getPrompt,
    getVendorReport,
    deleteVendorReport,
    getRunsCount,
    patchVendorReport,
} from '../../../actions';
import {
    utcToLocal,
    getFormatTs,
    getFormatHour,
    getFormatDays,
    getFormatInterval,
} from '../../../utils';

import { FaPencilAlt, FaSave } from 'react-icons/fa';

function Report() {

    const navigate = useNavigate();
    const { portfolioId, siteId, vendorId, reportId } = useParams();
    const { toTz } = useSelector(state => state.tz);

    const [ name, setName ] = useState( '' );
    const [ cameraName, setCameraName ] = useState( 'All cameras' );
    const [ promptName, setPromptName ] = useState( 'All prompts' );

    const [ filters, setFilters ] = useState( {} );
    const [ displayFilters, setDisplayFilters ] = useState( {} );
    const [ runsCount, setRunsCount ] = useState( -1 );
    const [ isArchived, setIsArchived ] = useState( true );

    const [ isLoading, setIsLoading ] = useState( true );
    const [ isDisabled, setIsDisabled ] = useState( true );
    const [ modalOpen, setModalOpen ] = useState( false );
    const [ modalType, setModalType ] = useState( '' );

    const resultsPerPage = 10;

    const toggle = ( type ) => {
        setModalOpen( !modalOpen );
        setModalType( modalType === type ? '' : type );
    };

    const searchParams = new URLSearchParams(filters).toString();

    function handleDelete() {

        deleteVendorReport( vendorId, reportId, (err, res) => {
            console.log( err, res );
            if( !err ) {
                navigate('/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId);
            }
        });

    }

    function handleArchive() {
        const patch = { isArchived: !isArchived };
        
        patchVendorReport( vendorId, reportId, patch, (err, res) => {
            console.log( err, res );
            if( !err ) {
                navigate('/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId);
            }
        });
        
    }

    useEffect( () => {
        
        function getCameraName( id ) {
            setIsLoading( true );
            getCamera( portfolioId, siteId, id, ( err, res ) => {
                console.log( err, res );
                setIsLoading( false );
                // if( !err ) setCameraName( res.data.name );
                // if( err ) setCameraName( '' );
                setCameraName( err ? 'N/A' : res.data.name );
            } );
        };

        function getPromptName( id ) {
            setIsLoading( true );
            getPrompt( portfolioId, siteId, id, ( err, res ) => {
                console.log( err, res );
                setIsLoading( false );
                // if( !err ) setPromptName( res.data.name );
                // if( err ) setPromptName( '' );
                setPromptName( err ? 'N/A' : res.data.name );
            } );
        };

        getVendorReport( vendorId, reportId, ( err, res ) => {
            setIsLoading( false );
            setIsDisabled( typeof res.data !== 'object' );
            if( !err && res.data ) {
                setName( res.data.name );
                setIsArchived( res.data.isArchived );
                
                const filters = res.data.filters;
                if( filters.cameraId ) getCameraName( filters.cameraId );
                if( filters.promptId ) getPromptName( filters.promptId );
                setFilters( filters );
            } /* else {
                setIsDisabled( true );
            } */
        });

    }, [ portfolioId, siteId, vendorId, reportId ] );

    useEffect(() => {
        if( isArchived ) return;
        
        getRunsCount( portfolioId, siteId, { ...filters, toTz }, ( err, res ) => {
            if( !err ) {
                console.log( 'res.data: ', res.data );
                setRunsCount( res.data.count );
            }
        });

    }, [ portfolioId, siteId, filters, toTz, isArchived ]);

    useEffect(() => {

        const headings = {
            triggered: 'Detections',
            interval: 'Date Range',
            fromTs: 'Start Date',
            toTs: 'End Date',
            startHour: 'Start hour',
            endHour: 'End hour',
            byDay: 'Repeat on',
        };

        const initialFilters = {
            'Camera': cameraName,
            'Prompt': promptName,
        };
        const sortedFilters = { ...initialFilters };

        function returnDisplayValue( key, value ) {
            switch( key ) {
                case 'triggered':
                    return (value === '1' ? true : false).toString();
                case 'fromTs':
                case 'toTs':
                    return getFormatTs( utcToLocal( value ) );
                case 'startHour':
                case 'endHour':
                    return getFormatHour(value);
                case 'byDay':
                    return getFormatDays(value);
                case 'interval':
                    return getFormatInterval(value);
                default:
                    return;
            }
        };

        Object.keys(headings).forEach(key => {
            const newKey = headings[key];
            if( filters.hasOwnProperty(key) ) {
                sortedFilters[newKey] = returnDisplayValue(key, filters[key]);
            }
        });

        setDisplayFilters( sortedFilters );

    }, [filters, cameraName, promptName]);

    return (
        <div className="App">

            <Header />  
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
    
                <SiteSidebar />
                <main className="w-100 p-3" style={{ display: 'flex', flexDirection: 'column' }}>
        
                    <div className="row border-bottom mb-2">
                        <Col sm={6}>
                            <h3>Vendor Report: {name}</h3>
                        </Col>
                        {isArchived ? (
                            <Col sm={6} className="text-end">
                                <Link to={-1}>Return</Link>
                            </Col>
                        ) : (
                            <Col sm={6} className="text-end">
                                <Button size="sm" className="me-2" onClick={() => toggle('download')} disabled={runsCount <= 0}>
                                    <FaSave/>Download Report
                                </Button>
                                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/' + reportId + '/edit'}>
                                    <Button size="sm" disabled={isArchived}>
                                        <FaPencilAlt />Edit Report
                                    </Button>
                                </Link>
                            </Col>
                        )}
                        
                    </div>

                    <Table id="report-filters" style={{ maxWidth: '100%', borderCollapse: 'collapse', marginBottom: 0 }}>
                        <tbody>
                            {isLoading && <tr><td><Spinner /></td></tr>}
                            {!isLoading && !isDisabled && Object.keys(displayFilters).map((key, index) => (
                                <tr key={index}>
                                    <th scope="row">{key}</th>
                                    <td>{displayFilters[key]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <br/>

                    <div className="row mt-2">
                        <RunsPreview
                            portfolioId={portfolioId}
                            siteId={siteId}
                            filters={filters}
                            resultsPerPage={resultsPerPage}
                            total={runsCount}
                            isDisabled={isArchived}
                        />
                    </div>

                    <div className="row mt-2">
                        <Col sm={6}>
                            {!isArchived && (
                                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?' + searchParams}>
                                    View All Runs
                                </Link>
                            )}
                        </Col>

                        <Col sm={6} className="text-end">
                            <Button className="me-2" size="sm"
                                onClick={() => toggle( 'archive' )}
                                disabled={isLoading || isDisabled}
                            >
                                {isArchived ? 'Restore Report From Archive' : 'Archive Report'}
                            </Button>
                            <Button color="danger" size="sm"
                                onClick={() => toggle( 'delete' )}
                                disabled={isLoading || isDisabled}
                            >
                                Delete Report
                            </Button>
                        </Col>
                    </div>

                    {modalType === 'delete' && (
                        <ConfirmationModal
                            isOpen={modalOpen}
                            onToggle={() => toggle( 'delete' )}
                            onConfirm={handleDelete}
                            header={'Delete Vendor Report'}
                            type={modalType}
                        >
                            <p>Are you sure you wish to delete report: <b>{name}</b>?</p>
                            <p>This action cannot be undone.</p>
                        </ConfirmationModal>
                    )}

                    {modalType === 'archive' && (
                        <ConfirmationModal
                            isOpen={modalOpen}
                            onToggle={() => toggle( 'archive' )}
                            onConfirm={handleArchive}
                            header={(isArchived ? 'Restore' : 'Archive') + ' Vendor Report'}
                            type={modalType}
                        >
                            <p>Are you sure you wish to {isArchived ? 'restore' : 'archive'} report: <b>{name}</b>?</p>
                        </ConfirmationModal>
                    )}

                    {modalType === 'download' && (
                        <DownloadModal
                            isOpen={modalOpen}
                            toggle={() => toggle( 'download' )}
                            type={modalType}
                            runsCount={runsCount}
                            displayFilters={displayFilters}
                        />
                    )}

                </main>
            </div>
        </div>
    );
}


export default Report;