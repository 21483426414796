import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardTitle, CardBody, CardImg, CardSubtitle } from 'reactstrap';
import { getRuns } from '../../actions';
import { timeAgo, getFormatTs } from '../../utils';

function Preview({ portfolioId, siteId, filters, ...props }) {
    const [ runs, setRuns ] = useState( [] );
    const { toTz } = useSelector(state => state.tz);
    
    const resultsPerPage = props.resultsPerPage || 10;
    const total = props.total || -1;
    const isDisabled = props.isDisabled;

    useEffect(() => {
        getRuns( portfolioId, siteId, { ...filters, toTz, resultsPerPage }, ( err, res ) => {
            // props.setIsLoading(false);
            if( !err ) {
                console.log( res.data );
                setRuns( res.data );
                // handleGetImageDataUrls( runs );
            }
        });

    }, [ portfolioId, siteId, filters, toTz, resultsPerPage ]);

    if( !isDisabled ) return (

        <React.Fragment>
            <Row>
                {runs.map((run, index) => (
                    <Col key={index} data-index={index} xs={12} sm={6} md={4} className="mb-4" style={{ maxHeight: '480px' }}>
                        <Card className="h-100">
                            <a href={run.imageUrl} target="_blank">
                                <CardImg
                                    className="runs-preview-img"
                                    src={run.imageUrl}
                                    top height="180px"
                                />
                            </a>
                            <CardBody>
                                <CardTitle tag="h5">
                                    <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs/' + run.id}>
                                        {run.id}: {run.prompt}
                                    </Link>
                                </CardTitle>
                                <CardSubtitle>
                                    <small className="text-muted">
                                        <div>{timeAgo( run.minutesElapsed )}</div>
                                        <div>{getFormatTs( run.createdTs )}</div>
                                        <div>{run.createdTs}</div>
                                    </small>
                                </CardSubtitle>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                
                {/* {isLoading && <Spinner/>} */}

            </Row>

            {total !== -1 && (
                <Row className="row mt-2">
                    <p className="text-center">{`Showing ${resultsPerPage} out of ${total}`}</p>
                </Row>
            )}

        </React.Fragment>
    )

}

export default Preview;