import { useState, useEffect } from 'react';
import { Table, Col, /* Row, */ Button, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/header';
import SiteSidebar from '../../components/siteSidebar';
import { getVendors } from '../../actions';

function Vendors() {

    const { portfolioId, siteId } = useParams();
    // const navigate = useNavigate();
    const [ vendors, setVendors ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( false );

    useEffect( () => {
        setIsLoading( true );
        const params = { query: '', page: 1 };
        getVendors( params, ( err, res ) => {
            setIsLoading( false );
            if( !err ) setVendors( res.data );
        });
    }, [] );

    // console.log( 'portfolioId: ', portfolioId );
    // console.log( 'siteId: ', siteId );
    // console.log( 'vendors: ', vendors );

    return (
        <div className="App">
            <Header />
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <SiteSidebar />
                <main className="w-100 p-3">
                    <div className="row border-bottom mb-2">
                        <Col sm={6}>
                            <h3>Vendors</h3>
                        </Col>
                        <Col sm={6} className="text-end">
                            <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/create'}><Button size="sm">Add Vendor</Button></Link>
                        </Col>
                    </div>

                    <Table>
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading &&
                                <tr>
                                    <td><Spinner /></td>
                                </tr>
                            }
                            {vendors.map((vendor, index) =>
                                <tr key={index}>
                                    <td>{vendor.id}</td>
                                    <td><Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendor.id}>{vendor.name}</Link></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </main>
            </div>
        </div>
    )

}

export default Vendors;