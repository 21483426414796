/**
    * @return {string} Offset between UTC and local timezone (-/+ HH:MM);
    * Used to convert UTC timestamp results to local before filtering by day
*/
export function getTimeZoneData() {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();

    /* const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
    const offsetMinutes = Math.abs(offsetInMinutes % 60);

    const formattedOffset = ( offsetInMinutes <= 0 ? '+': '-' ) +
        offsetHours.toString().padStart(2, '0') + ':' +
        offsetMinutes.toString().padStart(2, '0'); */

    return {
        offset: offsetInMinutes,
        // toTz: formattedOffset,
        toTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
}

/**
    * @param {string} localString local date/time in 24-hour format.
    * @return {string} UTC date & timestamp.
*/
export function localToUtc(localString) {
    try {
        const date = new Date(localString); 

        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());

        const utcString = (new Date(utcDate)).toISOString().replace('T', ' ').substring(0, 19);
        return utcString;  
    } catch( error ) {
        return null;
    }
}

/**
    * @param {string} utcString UTC date & timestamp.
    * @return {string} local date & timestamp in 24-hour format.
*/
export function utcToLocal(utcString) {
    try {
        const utcDate = new Date(utcString + " UTC"); // Parse as UTC
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
        const localString = localDate.toISOString().replace('T', ' ').substring(0, 19);
        return localString;  
    } catch( error ) {
        return null;
    }
    // return '';
}

/**
    * @param {string} hourString hour (24-hour format).
    * @return {string} corresponding value in 12-hour format.
*/
export function militaryHourToLocal(hourString) {
    try {
        const int = parseInt(hourString);

        if( int === 0 ) return '12 AM';
        else if ( int === 12 ) return '12 PM';
        else return int > 12 ? `${int - 12} PM`: `${int} AM`;
    } catch( error ) {
        return null;
    }
    
}

/**
    * @param {string} hourInt hour (24-hour format).
    * @return {string} hour converted to time (HH:mm:ss)
*/
export function militaryHourToTime(hourInt) {
    const timeString = `${hourInt.toString().padStart(2, '0')}:00:00`;
    return timeString;
}

/**
    * @param {string} timeStamp UTC timestamp (HH:MM:SS).
    * @return {string} UTC timestamp converted to local (24-hour format).
*/
export function timeToMilitaryHour(timeStamp) {
    // const date = new Date();
    // date.setUTCHours(hour);
    // const hourInt = date.getHours();
    // console.log( 'hourInt: ', hourInt );
    // return hourInt;
    const hourInt = parseInt(timeStamp.split(':')[0], 10);
    return hourInt;
}

/**
    * @param {int} int integer representing weekday. e.g. 1 = Monday.
    * @param {array} selectedDays array of integers representing selected weekdays
    * @return {string} updated array joined as string
*/
export function aggregateDaysArr(int, selectedDays) {
    try {
        const newSelectedDays = selectedDays.includes( int )
            ? selectedDays.filter(i => i !== int)
            : [...selectedDays, int]

        return newSelectedDays.length > 0 ? newSelectedDays.sort().toString(): null;
        
    } catch( error ) {
        return null;
    }
}


/* export function getToTimezone() {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();

    const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
    const offsetMinutes = Math.abs(offsetInMinutes % 60);

    const formattedOffset = ( offsetInMinutes <= 0 ? '+': '-' ) +
        offsetHours.toString().padStart(2, '0') + ':' +
        offsetMinutes.toString().padStart(2, '0');

    return formattedOffset;
} */

export const months = [
    'January', 'February', 'March',
    'April', 'May', 'June',
    'July', 'August', 'September',
    'October', 'November', 'December'
];

export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const hours = [...Array(24).keys()].map((hour) => {
    return { name: militaryHourToLocal(hour), value: hour };
});

export const intervalOptions = [
    { name: 'Past year', value: '1 YEAR' },
    { name: 'Past 6 months', value: '6 MONTH' },
    { name: 'Past 3 months', value: '3 MONTH' },
    { name: 'Past month', value: '1 MONTH' },
    { name: 'Past week', value: '7 DAY' },
    { name: 'Past 24 hours', value: '1 DAY' },
];

/**
    * each const below returns sanitized value for relevant element on UI
    * @param {string} ts UTC date & timestamp.
    * @param {string} hour UTC timestamp (HH:MM:SS).
    * @param {string} days array of days (correspond to MySQL values) joined as string e.g '1, 2, ..'.
    * @param {string} interval interval (correspond to MySQL value) e.g. '1 YEAR'.
    * @return {string} local date/time value in converted format.
*/

export const getFormatTs = ( ts, options ) => {
    const obj = options || {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
    };

    const formatTs = (new Date( ts )).toLocaleDateString('en-US', obj);
    return formatTs;
}

export const getFormatTime = ( ts ) => {
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    };

    const formatTime = (new Date( ts )).toLocaleTimeString('en-US', options);
    return formatTime;
}

export const getFormatHour = ( hour ) => {
    const selectedHour = hours.find(i => i.value === timeToMilitaryHour( hour ));
    return selectedHour.name;
}

export const getFormatDays = ( days ) => {
    const selectedDays = days.split(',').map(i => (weekdays[i]));
    return selectedDays.join(', ');
}

export const getFormatInterval = ( interval ) => {
    const selectedInterval = intervalOptions.find(i => i.value === interval);
    return selectedInterval.name;
}

export const tsOptionsDate = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
}