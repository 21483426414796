import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import {
    localToUtc,
    // militaryHourToLocal,
    militaryHourToTime,
    aggregateDaysArr,
    weekdays,
    intervalOptions,
    hours
} from '../../utils';

function DateTimeFilter(props) {

    const localFromTs = props.localFromTs;
    const localToTs = props.localToTs;
    const selectedDays = props.selectedDays;
    const militaryStartHour = props.militaryStartHour;
    const militaryEndHour = props.militaryEndHour;

    const [ selectedInterval, setSelectedInterval ] = useState( props.selectedInterval );

    function handleToggleInterval( value ) {
        props.setQueryParams({
            interval: intervalOptions.map(e => e.value).indexOf(value) !== -1 ? value : null,
            fromTs: null,
            toTs: null,
        });

        setSelectedInterval( value );
    }

    useEffect(() => {
        if( localFromTs.length > 0 || localToTs.length > 0 ) {
            setSelectedInterval( 'custom' );
        } else if( props.selectedInterval.length > 0 ) {
            setSelectedInterval( props.selectedInterval );
        }
    }, [localFromTs, localToTs, props.selectedInterval]);

    return (
        <React.Fragment>
            <Row className="row-cols-lg-auto g-3 align-items-center">

                <Col>
                    <FormGroup>
                        <Label>Date Range</Label>
                        <Input size="sm" type="select" value={selectedInterval} onChange={ e => handleToggleInterval( e.target.value ) }>
                            <option value={''}>All</option>
                            <option value={'custom'}>Custom</option>
                            {intervalOptions.map((option, index) => (
                                <option key={index} value={option.value}>{option.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>

                {selectedInterval === 'custom' && (
                    <React.Fragment>
                        <Col>
                            <FormGroup>
                                <Label>From</Label>
                                <Input size="sm" type="datetime-local" value={localFromTs} onChange={ e => props.setQueryParams({
                                    fromTs: localToUtc( e.target.value.replace( 'T', ' ' ) + ':00' ),
                                    interval: null
                                })} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label>To</Label>
                                <Input size="sm" type="datetime-local" value={localToTs} onChange={ e => props.setQueryParams({
                                    toTs: localToUtc( e.target.value.replace( 'T', ' ' ) + ':00' ),
                                    interval: null
                                })} />
                            </FormGroup>
                        </Col>
                    </React.Fragment>
                )}

            {/* </Row>

            <Row className="row-cols-lg-auto g-3 align-items-flex-start"> */}
                <Col>
                    <FormGroup>
                        <Label>Start Hour</Label>
                        <Input size="sm" type="select" value={militaryStartHour} onChange={ e => props.setQueryParams({ startHour: e.target.value === '-1' ? null : militaryHourToTime( e.target.value ) }) }>
                            <option value={'-1'}/>
                            {hours.map((obj, index) => (
                                <option key={index} value={obj.value}>{obj.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>End Hour</Label>
                        <Input size="sm" type="select" value={militaryEndHour} onChange={ e => props.setQueryParams({ endHour: e.target.value === '-1' ? null : militaryHourToTime( e.target.value ) }) }>
                            <option value={'-1'}/>
                            {hours.map((obj, index) => (
                                <option key={index} value={obj.value}>{obj.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            <Row className="row-cols-lg-auto g-3 align-items-center">
                <Col>
                    <FormGroup>
                        <Label>Repeat on</Label>
                        <Row>
                            {weekdays.map((day, index) => (
                                <Col key={index}>
                                    <FormGroup check>
                                        <Input
                                            type="checkbox"
                                            id={day}
                                            checked={selectedDays.includes(index)}
                                            onChange={() => props.setQueryParams({ byDay: aggregateDaysArr(index, selectedDays) })}
                                        />
                                        <Label check>
                                            {day}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                </Col>
            </Row>

        </React.Fragment>
    )

}

export default DateTimeFilter;