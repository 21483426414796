import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/header";
import SiteSidebar from "../../components/siteSidebar";
import { getReport } from "../../actions";
import { useParams } from "react-router-dom";
import { MdCircle, MdLooks } from "react-icons/md";
import { FaCamera } from "react-icons/fa";
import { CiCircleAlert } from "react-icons/ci";
import { BsBinoculars, BsBinocularsFill } from "react-icons/bs";
import { RiProgress1Line, RiProgress2Line, RiProgress3Line, RiProgress4Line, RiProgress5Line, RiProgress6Line, RiProgress7Line, RiProgress8Line } from "react-icons/ri";
import { Container, Row, Col } from "reactstrap";

export default function TimelineReport( props ) {

    const location = useLocation();
    const { portfolioId, siteId } = useParams();

    const queryParams = new URLSearchParams(location.search);
    const interval = queryParams.get('interval');
    const timeblock = queryParams.get('timeblock');
  
    const [ data, setData ] = useState( {} );
    const [ promptIds, setPromptIds ] = useState( [] );
    const [ timeblocks, setTimeblocks ] = useState( [] );
    const [ prompts, setPrompts ] = useState( {} );

    useEffect( () => {
        getReport( portfolioId, siteId, { interval, timeblock }, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                let data = {};
                let promptIds = [];
                let timeblocks = [];
                let prompts = {};
                res.data.map( d => {
                    const promptId = d['promptId'];
                    const timeblock = d['timeblock'];
                    if( !data[promptId] ) data[promptId] = {};
                    data[promptId][timeblock] = { triggered: d['triggered'], count: d['count'] };
                    if( promptIds.indexOf( promptId ) == -1 ) promptIds.push( promptId );
                    if( timeblocks.indexOf( timeblock ) == - 1 ) timeblocks.push( timeblock );
                    prompts[promptId] = d['prompt'];
                });
                // Sort timeblocks
                setData( data );
                setTimeblocks( timeblocks );
                setPromptIds( promptIds );
                setPrompts( prompts );
            }
        });
    }, [ interval, timeblock ] );


    let date = new Date(timeblock);
    date.setDate(date.getDate() - 1);
    const priorDay = date.toISOString().split('T')[0];
    date = new Date(timeblock);
    date.setDate(date.getDate() + 1);
    const nextDay = date.toISOString().split('T')[0];

    return (
        <div className="App">
        <Header />  
        <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
  
          <SiteSidebar />
          <main className="w-100 p-3">

            <Container>
                <h3>Timeline Report</h3>

                <Row>
                    <Col>
                        {interval === 'hour' && <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=hour&timeblock=' + priorDay}>Prior day</a>}
                    </Col>
                    <Col className="text-end">
                        {interval === 'hour' && <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=hour&timeblock=' + nextDay}>Next day</a>}
                    </Col>
                </Row>

                <table>
                    {interval === 'hour' && 
                        <tr>
                            <td colSpan={2} style={{width: '25%'}}></td>
                            <td colSpan={timeblocks.length} align="center" className="bg-primary fw-bold text-light">
                                {interval === 'hour' && (new Date( timeblock + ' UTC' )).toLocaleDateString('en-US', {weekday: 'long',  month: 'short', day: 'numeric', year: 'numeric'})}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td colSpan={2} className="border-bottom" style={{width: '25%'}}></td>
                        {timeblocks.map( timeblock => 
                            <td align="center"  className="bg-primary fw-bold text-light small text-nowrap ps-1 pe-1">
                                {interval === 'day' && 
                                    <>
                                        {(new Date( timeblock + ' UTC' )).toLocaleDateString('en-US', { weekday: 'short'})}
                                        <br />
                                        {(new Date( timeblock + ' UTC' )).toLocaleDateString('en-US', { month: 'short', day: 'numeric'})}
                                    </>
                                }
                                {interval === 'hour' && (new Date( timeblock + ' UTC' )).toLocaleTimeString('en-US', { hour: 'numeric'}).replace(/(\d+)\s([AP]M)/, '$1$2')}
                            </td>
                        )}
                    </tr>
                    {promptIds.map( promptId =>
                        <>
                            <tr>
                                <td rowSpan={2} className="border-bottom">{prompts[promptId]}</td>
                                <td className="border-end text-muted small">Detections</td>
                                {timeblocks.map( timeblock => {
                                    let triggered = data[promptId][timeblock] ? data[promptId][timeblock]['triggered'] : null;
                                    let count = data[promptId][timeblock] ? data[promptId][timeblock]['count'] : null;

                                    /*let progressIcon = '--';
                                    let pct = triggered / count;
                                    if( pct > 0 && pct <= 1/8 ) progressIcon = <RiProgress1Line />
                                    else if( pct > 1/8 && pct < 2/8 ) progressIcon = <RiProgress2Line />
                                    else if( pct > 2/8 && pct < 3/8 ) progressIcon = <RiProgress3Line />
                                    else if( pct > 3/8 && pct < 4/8 ) progressIcon = <RiProgress4Line />
                                    else if( pct > 4/8 && pct < 5/8 ) progressIcon = <RiProgress5Line />
                                    else if( pct > 5/8 && pct < 6/8 ) progressIcon = <RiProgress6Line />
                                    else if( pct > 6/8 && pct < 7/8 ) progressIcon = <RiProgress7Line />
                                    else if( pct > 7/8 && pct < 8/8 ) progressIcon = <RiProgress8Line />
                                    console.log( progressIcon );*/

                                    let pct = triggered / count;
                                    const color = `rgb(255, ${Math.round(255 * (1 - pct))}, ${Math.round(255 * (1 - pct))})`;

                                    const date = new Date(timeblock + ' UTC');
                                    date.setHours(date.getHours() + 1);
                                    const nextTimeblock = date.toISOString().replace('T', ' ').substring(0, 19);
                                
                                    return (
                                        <td style={{textAlign: 'center'}}>
                                            {interval === 'day' &&
                                                <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=hour&timeblock=' + timeblock}>
                                                    {triggered === 0 ? '-' : triggered}
                                                </a>
                                            }
                                            {interval === 'hour' &&
                                                <>
                                                    {/*<a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/reports/timeline?interval=ten&timeblock=' + timeblock}>*/}
                                                    {/*<a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?interval=hour&timeblock=' + timeblock + '&promptId=' + promptId + '&triggered=1'}>*/}
                                                    <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?interval=hour&fromTs=' + timeblock + '&toTs=' + nextTimeblock + '&promptId=' + promptId + '&triggered=1'}>
                                                        {triggered === 0 ? '-' : triggered}
                                                    </a>
                                                    <MdCircle style={{color: color}} />
                                                </>
                                            }
                                            
                                        </td>
                                    );
                                })}
                            </tr>
                            <tr>
                                <td className="border-end border-bottom text-muted small">Snapshots</td>
                                {timeblocks.map( timeblock => {
                                    let triggered = data[promptId][timeblock] ? data[promptId][timeblock]['triggered'] : null;
                                    let count = data[promptId][timeblock] ? data[promptId][timeblock]['count'] : null;

                                    return (
                                        <td className="border-bottom" style={{textAlign: 'center'}}>
                                            {interval === 'day' &&
                                                <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?interval=day&timeblock=' + timeblock + '&promptId=' + promptId}>
                                                    {count}
                                                </a>
                                            }
                                            {interval === 'hour' &&
                                                <a href={'/portfolios/' + portfolioId + '/sites/' + siteId + '/runs?interval=hour&timeblock=' + timeblock + '&promptId=' + promptId}>
                                                    {count}
                                                </a>
                                            }
                                        </td>
                                    );
                                })}
                            </tr>
                        </>
                    )}
                </table>
            
            </Container>
          </main>
        </div>
        </div>
    );
}
