import { useState, useEffect, useCallback } from 'react';
import { Col, Form, Button, /* Input, */ FormGroup, Label,
    ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { Link, /* Navigate, */ useNavigate, useParams } from 'react-router-dom';
import { getVendor, deleteVendor, getVendorReports } from '../../actions';
// import EditableLabel from '../../components/editableLabel';
import ReportFiltersPreview from './reports/filtersPreview';
import Header from '../../components/header';
import SiteSidebar from '../../components/siteSidebar';
import ConfirmationModal from '../../components/confirmationModal';

import { FaPencilAlt } from 'react-icons/fa';
import { MdAdd, MdTimelapse } from 'react-icons/md';


function Vendor() {

    const navigate = useNavigate();
    const { portfolioId, siteId, vendorId } = useParams();

    const [ name, setName ] = useState( '' );
    const [ reports, setReports ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( true );
    const [ isDisabled, setIsDisabled ] = useState( true );
    const [ modalOpen, setModalOpen ] = useState( false );

    const toggle = () => setModalOpen( !modalOpen );

    function handleDelete() {
        deleteVendor( vendorId, (err, res) => {
            console.log( err, res );
            if( !err ) {
                navigate('/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors');
            }
        });
    }

    const handleGetReports = useCallback(() => {
        getVendorReports( vendorId, { isArchived: false }, ( err, res ) => {
            if( !err ) {
                console.log( 'res: ', res );
                setReports( res.data );
            }
        });
    }, [ vendorId ]);

    const handleGet = useCallback(() => {
        getVendor( vendorId, ( err, res ) => {
            console.log( err, res );
            setIsLoading( false );
            if( !err ) {
                setIsDisabled( false );
                setName( res.data.name );
            }
        });
    }, [ vendorId ]);

    useEffect(() => {
        handleGet();
        handleGetReports();
    }, [ handleGet, handleGetReports ]);

    return (
        <div className="App">
            <Header />

            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
    
                <SiteSidebar />
                <main className="w-100 p-3">
                    <div className="row border-bottom mb-2">
                        <Col sm={6}>
                            <h3>Vendor: {name}</h3>
                        </Col>
                        <Col sm={6} className="text-end">
                            {!isDisabled && (
                                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/edit'}>
                                    <Button className="me-2" size="sm"><FaPencilAlt /> Edit Vendor</Button>
                                </Link>
                            )}
                        </Col>
                    </div>

                    <Form>
                        <FormGroup>
                            <Label>Report(s)</Label>
                            <ListGroup>
                                {isLoading &&
                                <ListGroupItem>
                                    <Spinner />
                                </ListGroupItem>
                                }
                                {reports.map((report, index) => {
                                    const filters = report.filters;
                                    return (
                                        <ListGroupItem key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/' + report.id}>
                                                {report.name}
                                            </Link>
                                            <ReportFiltersPreview
                                                {...filters}
                                            />
                                        </ListGroupItem>
                                    )
                                })}
                                <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {!isDisabled && (
                                        <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/create'}>
                                            <Button size="sm" outline><MdAdd /> Add Report</Button>
                                        </Link>
                                    )}
                                    <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/archive'}>
                                        <Button size="sm" outline><MdTimelapse /> View Archive</Button>
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                        </FormGroup>
                    </Form>

                    <div className="row mt-2">
                        <Col /* sm={6}  */className="text-end">
                            {!isDisabled && (
                                <Button color="danger" className="me-2" size="sm" onClick={toggle}>
                                    Delete Vendor
                                </Button>
                            )}
                        </Col>
                    </div>

                    {modalOpen && (
                        <ConfirmationModal
                            isOpen={modalOpen}
                            onToggle={toggle}
                            onConfirm={handleDelete}
                            header={"Delete Vendor"}
                            type="delete"
                        >
                            <p>Are you sure you wish to delete vendor: <b>{name}</b>?</p>
                            <sub>All site associations and reports will also be removed permanently.</sub>
                        </ConfirmationModal>
                    )}

                </main>
            </div>
            
        </div>
    );
}

export default Vendor;
