import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReportsSummary from './reports/summary';
import { getVendors } from '../../actions';

function VendorsSummary({ portfolioId, siteId }) {
    
    const [ vendors, setVendors ] = useState([]);
    const [ selectedVendorId, setSelectedVendorId ] = useState( '' );

    const handleGet = useCallback(() => {
        const params = { query: '', page: 1, siteId };
        getVendors( params, ( err, res ) => {
            if( !err ) {
                const vendors = res.data;
                setVendors( vendors );
                if( vendors.length > 0 ) setSelectedVendorId( vendors[0].id );
            }
        });
    }, [siteId]);

    useEffect(() => {
        handleGet();
    }, [handleGet]);

    return (
        <div>
            <h3>Vendors</h3>
            <Form>
                <FormGroup>
                    <Label>Vendor:</Label>
                    <Input
                        type="select"
                        style={{ width: '50%' }}
                        onChange={e => setSelectedVendorId(e.target.value)}
                    >
                        {vendors.map(vendor => (
                            <option value={vendor.id}>{vendor.name}</option>
                        ))}
                    </Input>
                </FormGroup>  
            </Form>

            {selectedVendorId !== '' && (
                <ReportsSummary
                    portfolioId={portfolioId}
                    siteId={siteId}
                    vendorId={selectedVendorId}
                />
            )}

            <div className="row mt-2 text-center">
                <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + selectedVendorId}>View All Reports</Link>
            </div>
        </div>
    )

}

export default VendorsSummary;