import { List } from 'reactstrap';
import {
    getFormatTs,
    // getFormatHour,
    getFormatDays,
    getFormatInterval,
} from '../../../utils';

function FiltersPreview( props ) {

    const toTs = props.toTs;
    const fromTs = props.fromTs;
    // const startHour = props.startHour;
    // const endHour = props.endHour;
    const byDay = props.byDay;
    const interval = props.interval;

    const tsOptions = {
        // weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // timeZoneName: 'short'
    };

    const formatToTs = toTs ? getFormatTs( toTs, tsOptions ) : '';
    const formatFromTs = fromTs ? getFormatTs( fromTs, tsOptions ) : '';
    const formatToFromTs = ( formatToTs.length > 0 || formatFromTs.length > 0 )
        ? `${formatFromTs} - ${formatToTs}`
        : '';
    const formatByDay = byDay ? getFormatDays( byDay ) : '';
    const formatInterval = interval ? getFormatInterval( interval ) : '';
    // const formatStartHour = startHour ? getFormatHour(startHour) : '';
    // const formatEndHour = endHour ? getFormatHour(endHour) : '';

    return (
        <List className="report-preview" type="unstyled" style={{ textAlign: 'end' }}>
            <li>{formatToFromTs}</li>
            <li>{formatInterval}</li>
            <li style={{ fontWeight: 200 }}>{formatByDay}</li>
        </List>
    )

}

export default FiltersPreview;