import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Row, Col,
    Form, FormGroup, FormFeedback, FormText, Label,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Spinner, Input, Button
} from 'reactstrap';

import SendModal from './send';

import {
    getDownloadJob,
    getDownloadFile,
    completeDownloadJob,
    downloadVendorReport
} from '../../../actions';
import { saveAs } from 'file-saver';

function DownloadModal( props ) {
    const { portfolioId, siteId, vendorId, reportId } = useParams();
    const { toTz } = useSelector( state => state.tz );
    const blobRef = useRef(null);

    const isOpen = props.isOpen;
    const totalImagesCount = props.runsCount;
    const summary = props.displayFilters;

    const [ sendModalOpen, setSendModalOpen ] = useState( false );
    const [ closeAll, setCloseAll ] = useState( false );

    const [ imagesLimit, setImagesLimit ] = useState( '20' );
    const [ orientation, setOrientation ] = useState( 'portrait' );
    const [ imagesPerRow, setImagesPerRow ] = useState( '2' );

    const [ isDownloading, setIsDownloading ] = useState( false );
    const [ jobId, setJobId ] = useState( '' );
    const [ jobStatus, setJobStatus ] = useState( '' );
    const [ previewUrl, setPreviewUrl ] = useState( '' );

    const noImagesLimit = ( imagesLimit === '' );
    const showImagesLimitWarning = ( noImagesLimit && totalImagesCount > 500 );
    const imagesLimitOfTotal = noImagesLimit ? '' : `${imagesLimit} of ${totalImagesCount}`;

    const toggleSendModal = () => {
        setSendModalOpen( !sendModalOpen );
        setCloseAll( false );
    }

    const toggleDownloadModal = () => {
        if( jobId ) {
            completeDownloadJob( jobId );
        }
        props.toggle();
    }

    const toggleAll = () => {
        setSendModalOpen( !sendModalOpen );
        setCloseAll( true );
    }

    function handleDownload() {
        const runsParams = {
            toTz: toTz,
            resultsPerPage: parseInt( imagesLimit ),
        };
        const params = {
            runsParams,
            orientation,
            imagesPerRow,
            imagesLimitOfTotal,
            summary,
            toTz,
        };
        // console.log( 'params: ', params );
        downloadVendorReport( portfolioId, siteId, vendorId, reportId, params, (err, res) => {
            setIsDownloading( true );
            setJobId( res.data.jobId );
            setJobStatus( 'Job started. Polling for status' );
            if( previewUrl ) {
                URL.revokeObjectURL( previewUrl );
                setPreviewUrl( '' );
            }
            if( blobRef.current ) blobRef.current = null;
        });
    }

    function handleSaveDownload() {
        if( !blobRef.current ) return;
        const filename = jobId.split('-')[0] + '.pdf';

        saveAs( blobRef.current, filename );
    }

    useEffect(() => {
        if( !jobId ) return;

        function handleFetchFile( jobId ) {
            getDownloadFile( jobId, (err, res) => {
                if( !err ) {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    blobRef.current = blob;
                    const url = URL.createObjectURL(blob);
                    setPreviewUrl( url + '#toolbar=0&view=fit' );
                }
            });
        }

        const interval = setInterval(() => {
            getDownloadJob( jobId, (err, res) => {
                setJobStatus( res.data.status );
                if( res.data.hasBuffer ) {
                    handleFetchFile( jobId );
                }
                if(
                    res.data.status === 'completed'
                    || res.data.status === 'error'
                    || res.data.status === 'none'
                ) {
                    setIsDownloading( false );
                    clearInterval( interval );
                }
            });
        }, 2000);

        return () => clearInterval( interval );

    }, [ portfolioId, siteId, jobId ]);

    useEffect(() => {
        return () => {
            if( previewUrl ) {
                URL.revokeObjectURL( previewUrl )
            }
        }
    }, [ previewUrl ]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleDownloadModal}
            fullscreen
            scrollable
        >
            <ModalHeader toggle={toggleDownloadModal}>
                Download Vendor Report
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                <Form>
                    <FormGroup>
                        <Label for="printOrientation">Orientation</Label>
                        <Input
                            type="select"
                            id="printOrientation"
                            value={orientation}
                            onChange={(e) => setOrientation( e.target.value )}
                        >
                            <option>landscape</option>
                            <option>portrait</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="imagesPerRow">Images Per Row</Label>
                        <Input
                            type="select"
                            id="imagesPerRow"
                            value={imagesPerRow}
                            onChange={(e) => setImagesPerRow( e.target.value )}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </Input>
                    </FormGroup>

                    <br/>
                    <FormGroup>
                        <Label for="imagesLimit">Images Limit</Label>
                        <Input
                            type="select"
                            id="imagesLimit"
                            value={imagesLimit}
                            onChange={(e) => setImagesLimit(e.target.value)}
                            invalid={showImagesLimitWarning}
                        >
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                            <option value={''}>All images</option>
                        </Input>
                        <FormText>
                            Printing { noImagesLimit ? totalImagesCount : imagesLimit } of { totalImagesCount }
                        </FormText>
                        {showImagesLimitWarning && (
                            <FormFeedback>
                                Larger image sets will take longer to download.
                            </FormFeedback>
                        )}
                    </FormGroup>
                </Form>

                <Row>
                    <Col md={{ offset: 3, size: 6 }} sm="12">
                        <div className="d-flex justify-content-center align-items-center">
                            {isDownloading && <Spinner/>}
                            {jobStatus !== '' && <div>{jobStatus}</div>}
                        </div>
                    </Col>
                </Row>

                {previewUrl !== '' && (
                    <Row style={{ flexGrow: 1 }}>
                        <Col xs={12} sm={8} className="mx-auto">
                            <iframe
                                title="Download Preview"
                                src={previewUrl}
                                width="100%"
                                height="100%"
                                // minHeight="600px"
                                style={{ border: 'none' }}
                            />
                        </Col>
                    </Row>
                )}

                {sendModalOpen && (
                    <SendModal
                        isOpen={sendModalOpen}
                        closeAll={closeAll}
                        toggle={toggleSendModal}
                        toggleAll={toggleAll}
                        toggleMain={toggleDownloadModal}
                        jobId={jobId}
                    />
                )}

            </ModalBody>
            <ModalFooter style={ blobRef.current ? { justifyContent: 'space-between' } : {} }>
                
                {blobRef.current && (
                    <div>
                        <Button onClick={handleSaveDownload} className="me-2">
                            Save
                        </Button>
                        <Button onClick={toggleSendModal}>
                            Send as Email
                        </Button>
                    </div>
                )}
                <div>
                    <Button disabled={isDownloading} onClick={toggleDownloadModal} className="me-2">
                        Cancel
                    </Button>
                    <Button disabled={isDownloading} outline color={"primary"} onClick={handleDownload}>
                        Confirm
                    </Button>
                </div>
            </ModalFooter>

        </Modal>
    )

}

export default DownloadModal;