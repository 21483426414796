import { useState, useEffect, useCallback } from 'react';
import { Form, Label, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Header from '../../components/header';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import { getVendor, patchVendor } from '../../actions';


function Edit() {

    const { /* portfolioId, siteId, */ vendorId } = useParams();

    const [ name, setName ] = useState( '' );

    const handleGet = useCallback(() => {
        getVendor( vendorId, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                setName( res.data.name );
            }
        });
    }, [vendorId]);

    const handleUpdate = patch => {
        patchVendor( vendorId, patch, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                handleGet();
            }
        });
    };

    useEffect( () => {
        handleGet();
    }, [ handleGet ] );

    return (
        <div className="App">
        
            <Header />
    
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
    
            <SiteSidebar />
    
            <main className="w-100 p-3">

                <h3>Vendor: {name}</h3>

                <Form className="border p-3 mb-3">
                    <div className="row">
                        <Label sm={2}>Name</Label>
                        <Col sm={10}>
                            <EditableLabel 
                                value={name}
                                onSave={ value => handleUpdate({ name: value }) }
                            />
                        </Col>
                    </div>
                </Form>

                </main>

            </div>
        
        </div>
    );

}

export default Edit;
