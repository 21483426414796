// import {
//     getFormatTs,
//     getFormatHour,
//     getFormatDays,
//     getFormatInterval
// } from "./dateTime";

// export const headings = {
//     triggered: 'Detections',
//     interval: 'Date Range',
//     fromTs: 'Start Date',
//     toTs: 'End Date',
//     startHour: 'Start hour',
//     endHour: 'End hour',
//     byDay: 'Repeat on',
// };

// export function returnDisplayValue( key, value ) {
//     switch( key ) {
//         case 'triggered':
//             return (value === '1' ? true : false).toString();
//         case 'fromTs':
//         case 'toTs':
//             return getFormatTs(value);
//         case 'startHour':
//         case 'endHour':
//             return getFormatHour(value);
//         case 'byDay':
//             return getFormatDays(value);
//         case 'interval':
//             return getFormatInterval(value);
//         default:
//             return;
//     }
// };