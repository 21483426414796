import { legacy_createStore as createStore } from 'redux';

function reducer( state, action ) {
    switch ( action.type ) {
        case 'SET_USER':
            return { ...state, user: {...action.payload} };
        case 'SET_TIMEZONE':
            return { ...state, tz: {...action.payload} };
        default:
            return state;
    }
}

const initialState = {
    user: {
        id: -1,
        username: ''
    },
    tz: {
        offset: 0, // total minutes
        // toTz: 'UTC', // add/subtract hours, minutes 
        name: 'UTC',
    }
};

const store = createStore( reducer, initialState );
export default store;