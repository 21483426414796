import { useState } from 'react';
import Header from './../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, FormText } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { createVendor } from '../../actions';
import Sidebar from '../../components/siteSidebar';


function Create() {

    const navigate = useNavigate();
    const { portfolioId, siteId } = useParams();

    // const [ type, setType ] = useState( '' );
    const [ name, setName ] = useState( '' );

    const handleCreate = () => {
        createVendor( siteId, { name }, ( err, res ) => {
            console.log( err, res );
            if( res.status === 200 ) {
                navigate( '/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + res.data.vendorId );
            }
        });
    }

    return (
        <div className="App">
            <Header />
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <Sidebar />
                <main className="w-100 p-3">

                <Container>
                    <h3>Add new vendor</h3>
                    <p>
                        Please contact us at <a href="mailto:setup@groundskeeper.ai">setup@groundskeeper.ai</a> if you require setup assistance.
                    </p>
                    
                    <Form>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input value={name} onChange={ e => setName( e.target.value ) } />
                            <FormText>
                                Vendor's Name.<br />
                                For example: Business, Inc.
                            </FormText>
                        </FormGroup>

                        {/* <FormGroup>
                            <Label>Address</Label>
                            <Input value={address} onChange={ e => setAddress( e.target.value ) } />
                            <FormText>
                                Vendor's Address.<br />
                                For example: 123 South Haven Court.
                            </FormText>
                        </FormGroup> */}

                        <Button onClick={handleCreate}>Create new vendor</Button>
                    </Form>

                </Container>
                
                </main>
            </div>
        
        </div>
    );
}

export default Create;
