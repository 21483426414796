import { useState, useEffect, useCallback } from 'react';
import Header from './../../../components/header';
import { Form, Label, Col, Button } from 'reactstrap';
import { /* Link, Navigate, */ useParams } from 'react-router-dom';
import { getVendorReport, patchVendorReport } from '../../../actions';
import EditableLabel from '../../../components/editableLabel';
import SiteSidebar from '../../../components/siteSidebar';
import CameraAndPromptFilter from '../../../components/runs/cameraAndPromptFilter';
import DateTimeFilter from '../../../components/runs/dateTimeFilter';
import { timeToMilitaryHour, utcToLocal } from '../../../utils';

function Edit() {

    const { portfolioId, siteId, vendorId, reportId } = useParams();
  
    const [ name, setName ] = useState( '' );
    const [ filters, setFilters ] = useState( {} );

    const localFromTs = filters.fromTs ? utcToLocal( filters.fromTs ) : '';
    const localToTs = filters.toTs ? utcToLocal( filters.toTs ) : '';
    const selectedDays = filters.byDay ? filters.byDay.split(',').map(i => parseInt(i)): [];
    const militaryStartHour = filters.startHour ? timeToMilitaryHour( filters.startHour ): -1;
    const militaryEndHour = filters.endHour ? timeToMilitaryHour( filters.endHour ): -1;
    const selectedInterval = filters.interval || '';
  
    const handleGet = useCallback(() => {
        getVendorReport( vendorId, reportId, ( err, res ) => {
            if( !err ) {
                setName( res.data.name );
                setFilters( res.data.filters );
            }
        });
    }, [ vendorId, reportId ] );
  
    const handleUpdate = patch => {
        patchVendorReport( vendorId, reportId, patch, ( err, res ) => {
            console.log( err, res );
            if( !err ) {
                handleGet();
            }
        });
    };

    const setQueryParams = (newParams) => {

        const iterable = (newParams, prevValue) => {
            let query = {...prevValue};
            Object.keys(newParams).forEach(key => {
                if( newParams[key] === null ) {
                    delete query[key]
                } else {
                    query[key] = newParams[key]
                }
            });
            return query;
        };

        setFilters(prevValue => iterable( newParams, prevValue ));

    };
  
    useEffect( () => {
        handleGet();
    }, [handleGet] );

  
    return (
        <div className="App">
            <Header />
    
    
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
    
                <SiteSidebar />

                <main className="w-100 p-3">
                    <h3>Vendor Report: {name}</h3>

                    <Form className="border p-3 mb-3">
                        <div className="row">
                        <Label sm={2}>Name</Label>
                        <Col sm={10}>
                            <EditableLabel 
                            value={name}
                            onSave={ value => handleUpdate({ name: value }) }
                            />
                        </Col>
                        </div>
                        
                    </Form>

                    <Form className="border p-3 mb-3">
                        
                        <CameraAndPromptFilter
                            setQueryParams={setQueryParams}
                            portfolioId={portfolioId}
                            siteId={siteId}
                            cameraId={filters.cameraId}
                            promptId={filters.promptId}
                            triggered={filters.triggered}
                        />
                        
                        <DateTimeFilter
                            setQueryParams={setQueryParams}
                            localFromTs={localFromTs}
                            localToTs={localToTs}
                            militaryStartHour={militaryStartHour}
                            militaryEndHour={militaryEndHour}
                            selectedDays={selectedDays}
                            selectedInterval={selectedInterval}
                        />

                        <Button onClick={() => handleUpdate({ filters })}>Update filters</Button>
                    </Form>
                </main>
    
            </div>
        
        
        </div>
    );

}

export default Edit;
