import React, { useEffect } from 'react';
import './App.css';
import store from './store';
import { getTimeZoneData } from './utils';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/home';
import Login from './pages/login';
import Portfolios from './pages/portfolios';
import Portfolio from './pages/portfolios/portfolio';
import CreatePortfolio from './pages/portfolios/create';
import EditPortfolio from './pages/portfolios/edit';
import CreateSite from './pages/sites/create';
import Site from './pages/sites/site';
import SiteSettings from './pages/sites/settings';
import Runs from './pages/runs';
import Run from './pages/runs/run';
import Cameras from './pages/cameras';
import Camera from './pages/cameras/camera';
import CreateCamera from './pages/cameras/create';
import CameraSnapshot from './pages/cameras/snapshot';
import Snapshots from './pages/snapshots';
import Prompts from './pages/prompts';
import Prompt from './pages/prompts/prompt';
import PromptSettings from './pages/prompts/settings';
import CreatePrompt from './pages/prompts/create';
import Incidents from './pages/incidents';
import Incident from './pages/incidents/incident';
import MislabeledIncident from './pages/incidents/mislabeled';
import Contacts from './pages/contacts';
import CreateContact from './pages/contacts/create';
import Admin from './pages/admin';
import CreateUser from './pages/users/create';
import TimelineReport from './pages/reports/timeline';
import CountReport from './pages/reports/count';
import Vendors from './pages/vendors';
import Vendor from './pages/vendors/vendor';
import CreateVendor from './pages/vendors/create';
import EditVendor from './pages/vendors/edit';
import VendorReport from './pages/vendors/reports';
import VendorReportArchive from './pages/vendors/reports/archive';
import CreateVendorReport from './pages/vendors/reports/create';
import EditVendorReport from './pages/vendors/reports/edit';

function App() {

  const domain = window.location.hostname;
  console.log( 'App.js', domain );
  const validHosts = [
    'dev2-app.groundskeeper.ai',
    'app.groundskeeper.ai'
  ];

  useEffect(() => {
    store.dispatch({ type: 'SET_TIMEZONE', payload: getTimeZoneData() });
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {validHosts.indexOf( domain ) !== -1 &&
              <>
                <Route path="/" element={<Home />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/users/create" element={<CreateUser />} />
                <Route path="/login" element={<Login />} />
                <Route path="/portfolios" element={<Portfolios />} />\
                <Route path="/portfolios/create" element={<CreatePortfolio />} />
                <Route path="/portfolios/:portfolioId" element={<Portfolio />} />
                <Route path="/portfolios/:portfolioId/edit" element={<EditPortfolio />} />
                <Route path="/portfolios/:portfolioId/sites/create" element={<CreateSite />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId" element={<Site />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/settings" element={<SiteSettings />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/runs" element={<Runs />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/runs/:runId" element={<Run />} />                
                <Route path="/portfolios/:portfolioId/sites/:siteId/incidents" element={<Incidents />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/incidents/:incidentId" element={<Incident />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/incidents/:incidentId/mislabeled" element={<MislabeledIncident />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/cameras" element={<Cameras />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/cameras/:cameraId" element={<Camera />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/cameras/create" element={<CreateCamera />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/cameras/:cameraId/snapshot" element={<CameraSnapshot />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/prompts" element={<Prompts />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/prompts/:promptId" element={<Prompt />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/prompts/:promptId/settings" element={<PromptSettings />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/prompts/create" element={<CreatePrompt />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/contacts" element={<Contacts />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/contacts/create" element={<CreateContact />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/reports/timeline" element={<TimelineReport />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/reports/count" element={<CountReport />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/snapshots" element={<Snapshots />} />
                
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors" element={<Vendors />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/create" element={<CreateVendor />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId" element={<Vendor />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId/edit" element={<EditVendor />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId/reports/:reportId" element={<VendorReport />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId/reports/archive" element={<VendorReportArchive />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId/reports/create" element={<CreateVendorReport />} />
                <Route path="/portfolios/:portfolioId/sites/:siteId/vendors/:vendorId/reports/:reportId/edit" element={<EditVendorReport />} />

              </>
            }
            {validHosts.indexOf( domain ) === -1 &&
              <>
                {/*<Route path="/:path?/:subPath?" element={<Site />} />*/}
              </>
            }
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
