import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Spinner, FormFeedback } from 'reactstrap';
import ConfirmationModal from '../../../components/confirmationModal';
import { sendVendorReport } from '../../../actions';

function SendModal( props ) {
    const { vendorId, reportId } = useParams();

    const isOpen = props.isOpen;
    const closeAll = props.closeAll;
    const jobId = props.jobId;
    
    const [ sendParams, setSendParams ] = useState({
        recipientName: '',
        recipientEmail: '',
    });

    const [ isSending, setIsSending ] = useState( false );
    const [ error, setError ] = useState( null );

    function handleSubmit() {
        setIsSending( true );
        sendVendorReport( vendorId, reportId, jobId, sendParams, (err, res) => {
            setIsSending( false );
            if( !err ) {
                props.toggle();
            }
            if( err ) {
                setError( err );
            }
        });
    }

    return (
        <ConfirmationModal
            isOpen={isOpen}
            onToggle={props.toggle}
            header="Send Report"
            onConfirm={handleSubmit}
            onClose={closeAll ? props.toggleMain : undefined}
            bodyStyle={{}}
        >
            <Form>
                <FormGroup>
                    <Label>Recipient Email</Label>
                    <Input
                        value={sendParams.recipientEmail}
                        onChange={(e) => setSendParams( prevVal => ({ ...prevVal, recipientEmail: e.target.value }) )}
                        invalid={!!error}
                    />
                    {error && (
                        <FormFeedback>
                            {error.message}
                        </FormFeedback>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Recipient Name</Label>
                    <Input
                        value={sendParams.recipientName}
                        onChange={(e) => setSendParams( prevVal => ({ ...prevVal, recipientName: e.target.value }) )}
                    />
                </FormGroup>
            </Form>

            {isSending && <Spinner/>}
        </ConfirmationModal>
    )

}

export default SendModal;