import { useState, useEffect } from 'react';
import Header from './../../components/header';
import { Col, Form, Button, Input, InputGroup, FormGroup, Label, Container, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSite, patchSite, getCameras, getPrompts, getRuns } from '../../actions';
import OpenIncidents from './../../components/incidents/open';
import IncidentsListGroup from './../../components/incidents/listGroup';
import RunsStream from '../../components/runs/stream';
import Breadcrumb from '../../components/breadcrumb';
import EditableLabel from '../../components/editableLabel';
import SiteSidebar from '../../components/siteSidebar';
import VendorsSummary from '../../components/vendors/summary';
import { MdHome } from 'react-icons/md';


function Site() {

  const navigate = useNavigate();
  const { portfolioId, siteId } = useParams();

  const [ name, setName ] = useState( '' );
  const [ workerUrl, setWorkerUrl ] = useState( '' );
  const [ cameras, setCameras ] = useState( [] );
  const [ prompts, setPrompts ] = useState( [] );

  useEffect( () => {
    handleGet();
  }, [] );

  const handleGet = () => {
    getSite( portfolioId, siteId, ( err, res ) => {
        if( !err ) {
            setName( res.data.name );
            setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });

    getCameras( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setCameras( res.data );
      }
    });

    getPrompts( portfolioId, siteId, {}, ( err, res ) => {
      if( !err ) {
        setPrompts( res.data );
      }
    });

};


  const handleUpdate = patch => {
    patchSite( portfolioId, siteId, patch, ( err, res ) => {
        console.log( err, res );
        if( !err ) {
          handleGet();
            //setName( res.data.name );
            //setWorkerUrl( res.data.workerUrl );
            //setSites( res.data.sites );
        }
    });
  };


  return (
    <div className="App">
      <Header />


      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>

        <SiteSidebar />

        <main className="w-100 p-3">

          <h3><MdHome className="mb-2" color="gray" /> {name}</h3>

            {/*<a href={''}>Timeline Report</a>*/}

            <OpenIncidents
              portfolioId={portfolioId}
              siteId={siteId}
            />

            <RunsStream
              portfolioId={portfolioId}
              siteId={siteId}
            />

            <VendorsSummary
              portfolioId={portfolioId}
              siteId={siteId}
            />

            <FormGroup>
              <Label>Cameras</Label>
              {' '}
              <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/create'}>Add Camera</Link>
              <ListGroup>
                {cameras.map( camera =>
                  <ListGroupItem>
                    <img src={camera.recentImageUrl} className="frame-small" />
                    <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/cameras/' + camera.id}>{camera.name}</Link>
                    {' '}
                    <a target="_blank" href={camera.url}>View</a>
                    {' '}
                    <Badge>{camera.openIncidents} Open incidents</Badge>
                    {' '}
                    {camera.numErrors > 0 && <Badge color="danger">{camera.numErrors} Errors</Badge>}
                  </ListGroupItem>
                )}
              </ListGroup>
            </FormGroup>

            <FormGroup>
              <Label>Prompts</Label>
              {' '}
              <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/create'}>Add Prompt</Link>
              <ListGroup>
                {prompts.map( prompt =>
                  <ListGroupItem>
                    {!prompt.enabled && <><Badge color="danger">Disabled</Badge>{' '}</>}
                    <Link to={'/portfolios/' + portfolioId + '/sites/' + siteId + '/prompts/' + prompt.id}>{prompt.prompt}</Link>
                    {' '}
                    <Badge>{prompt.openIncidents} Open incidents</Badge>
                  </ListGroupItem>
                )}
              </ListGroup>
            </FormGroup>


          </main>

          </div>

      
    </div>
  );
}

export default Site;
