import { useState } from 'react';
import Header from '../../../components/header';
import { Form, Button, Input, FormGroup, Label, Container, FormText } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { createVendorReport } from '../../../actions';
import Sidebar from '../../../components/siteSidebar';
import CameraAndPromptFilter from '../../../components/runs/cameraAndPromptFilter';
import DateTimeFilter from '../../../components/runs/dateTimeFilter';
import { timeToMilitaryHour, utcToLocal } from '../../../utils';

function Create() {

    const navigate = useNavigate();
    const { portfolioId, siteId, vendorId } = useParams();

    const [ name, setName ] = useState( '' );
    const [ filters, setFilters ] = useState({});

    const localFromTs = filters.fromTs ? utcToLocal( filters.fromTs ) : '';
    const localToTs = filters.toTs ? utcToLocal( filters.toTs ) : '';
    const selectedDays = filters.byDay ? filters.byDay.split(',').map(i => parseInt(i)): [];
    const militaryStartHour = filters.startHour ? timeToMilitaryHour( filters.startHour ): -1;
    const militaryEndHour = filters.endHour ? timeToMilitaryHour( filters.endHour ): -1;
    const selectedInterval = filters.interval || '';

    const handleCreate = () => {
        createVendorReport( vendorId, { name, filters }, ( err, res ) => {
            console.log( err, res );
            if( res.status === 200 ) {
                navigate( '/portfolios/' + portfolioId + '/sites/' + siteId + '/vendors/' + vendorId + '/reports/' + res.data.reportId );
            }
        });
    }

    const setQueryParams = (newParams) => {
        
        const iterable = (newParams, prevValue) => {
            let query = {...prevValue};
            Object.keys(newParams).forEach(key => {
                if( newParams[key] === null ) {
                    delete query[key]
                } else {
                    query[key] = newParams[key]
                }
            });
            return query;
        };

        setFilters(prevValue => iterable( newParams, prevValue ));

    }

    // console.log( 'hours: ', hours );
    // console.log( 'startHour: ', startHour );
    // console.log( 'endHour: ', endHour );
    console.log( 'filters: ', filters );

    return (
        <div className="App">
            <Header />
            <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <Sidebar />
                <main className="w-100 p-3">

                <Container>
                    <h3>Create New Report</h3>
                    
                    <Form>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input value={name} onChange={ e => setName( e.target.value ) } />
                            <FormText>
                                Name of Report
                            </FormText>
                        </FormGroup>
                    </Form>

                    <Form>
                        <CameraAndPromptFilter
                            setQueryParams={setQueryParams}
                            portfolioId={portfolioId}
                            siteId={siteId}
                            cameraId={filters.cameraId}
                            promptId={filters.promptId}
                            triggered={filters.triggered}
                        />

                        <DateTimeFilter
                            setQueryParams={setQueryParams}
                            localFromTs={localFromTs}
                            localToTs={localToTs}
                            militaryStartHour={militaryStartHour}
                            militaryEndHour={militaryEndHour}
                            selectedDays={selectedDays}
                            selectedInterval={selectedInterval}
                        />

                        <Button onClick={handleCreate}>Create vendor report</Button>
                    </Form>

                </Container>
                
                </main>
            </div>
        
        </div>
    );
}

export default Create;
